<template>
    <div class="img-warp app-content">
        <div class="img-main">
            <swiper class="swiper poster" :options="swiperOption">
                <swiper-slide  v-for="(item, key) in list" :key = key >
                     <img :src="item" alt="">
                </swiper-slide>
            </swiper>
            <img class="close" src="//txwl.internetbaidu.com/mobileTXW_3.0/others/close.png" alt="" @click="close">
        </div>
    </div>
</template>
<script>
export default {
  name: "txImg",
  props: {
    // 当前初始化索引值
    imgIndex: {
        type: Number,
        default: 0
    },
    // 当前swiper 数组
    list: {
        type: Array
    },
  },
  data () {
    return {
        swiperOption: {
          initialSlide: this.imgIndex
        }
    };
  },
  methods: {
      close() {
         this.$emit('closeImg', false)
      }
  }
}
</script>
<style lang="less" scoped>
.img-warp {
    position: fixed;
    z-index: 99;
    top: 0;
    left: 0;
    background: rgba(0,0,0,.8);
    .img-main {
        width: 100%;
        height: 100%;
        position: relative;
        .poster {
            position: absolute;
            width: 100%;
            height: 640px;
            top: 30%;
            left: 0;
            img {
                width: 100%;
                height: 100%;
            }
        }
        img.close {
            position: absolute;
            width: 90px;
            height: 90px;
            top: 31%;
            right: 1%;
            z-index: 10;
        }
    }
  }
</style>