<template>
  <div id="app">
     <header-nav></header-nav>
     <div class="app-body">
      <router-view></router-view> 
     </div>
     <contact></contact>
  </div>
</template>

<script>

export default {
  name: "App",
  mounted() {
    this.checkDevice();
    
    // 切换屏幕重新加载
    window.addEventListener("orientationchange",function() {
      window.location.reload(true)
    },false);

    // 禁用双指缩放
    document.documentElement.addEventListener('touchstart', function (event) {
      if (event.touches.length > 1) {
        event.preventDefault();
      }
    }, false);

    // 禁用手指双击缩放
    var lastTouchEnd = 0;
    document.documentElement.addEventListener('touchend', function (event) {
      var now = Date.now();
      if (now - lastTouchEnd <= 300) {
        event.preventDefault();
      }
      lastTouchEnd = now;
    }, false);

  },
  methods: {
    checkDevice () {
      const isMobile = (navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i))
      if (!isMobile && location.host === 'm.txwl.com') {
        // location.href = 'https://txwl.com'
      }
    }
  }
};
</script>

<style lang="less">
  html, body, #app{
      width: 100%;
      height: 100%;
      overflow: hidden;
      background: #1B1936;
      color: #fff;
  }
  .app-body {
    padding-top: 135px;
    height: 100%;
  }
  .app-content {
    width: 100%;
    height: 100%;
  }
</style>

