<template>
    <div class="announcement-warp" v-if="show && isIndex">
        <div class="announcement-content" ref="contentBox" :style="{top: isTop, height:contenH}">
            <div class="announcement-header">
                紧急公告 谨防诈骗
                <div class="close" @click="show = false;"></div>
            </div>
            <div class="main" ref="main">
                <div class="btns">
                    <span :class="{active: currentIndex === 0}" @click="currentIndex = 0">假冒账号</span>
                    <span :class="{active: currentIndex === 1}" @click="currentIndex = 1">假冒官网</span>
                </div>
                <div class="content" v-show="currentIndex === 0" >
                    <p><span>紧急通知：</span>近期有不良商家注册相似Skype账号，假冒天下网络市场部人员行骗，请务必通过官网展示的联系方式联系我司，谨防上当！</p>
                    <h2>注：我司已完全关闭Skype联系方式，汇款前请务必Telegram联系！</h2>
                    <div class="imgBg" :style="{height: calcHeight + 'px'}">
                    </div>
                    <h2>注：请认准天下集团官网账户，其他均为诈骗！</h2>
                </div>
                <div class="content" v-show="currentIndex === 1">
                    <p><span>紧急通知：</span>近期有不良商家假冒天下网络官方网址，假冒天下网络公司行骗，请务必通过官网展示的联系方式联系我司，谨防上当！</p>
                    <h2>天下网络唯一官方网址：txwl.com</h2>
                    <img style="margin-bottom: 15px" src="//txwl.internetbaidu.com/mobileTXW_3.0/index/jmgw.png" alt="">
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
  name: "announcement",
  data () {
    return {
        show: true,
        currentIndex: 0,
        isTop: '',
        calcHeight:'',
        contenH: "auto"
    };
  },
  computed: {
      // 是否为首页
      isIndex() {
          return window.location.pathname === '/index'
      }
  },
  mounted() {
    //html中两img标签 影藏与显示操作时 每次都重新加载  改为background
    //更具图片的实际长宽比例 设置div的宽高
    //this.calcHeight = this.$refs.main.offsetWidth *(727 / 853);
   /* this.$nextTick(() => {
      this.calcHeight = this.$refs.main.offsetWidth *(727 / 853);
    })*/
    this.setPopPosition();

  },
  methods: {
    setPopPosition(){
      // eslint-disable-next-line no-unused-vars
      let contentH;
      let viewH = document.body.clientHeight;
      this.$nextTick(() => {
        if (this.$refs.contentBox) {
          contentH = this.$refs.contentBox.offsetHeight;
          if (contentH < viewH) {
            this.isTop = (viewH - contentH) / 2  /viewH * 100 + '%';
          } else {
            this.isTop = 0;
            this.contenH = '100%';
          }
        }

      })

    }
  }
}
</script>
<style lang="less" scoped>
.announcement-warp {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100%;
    z-index: 9999;
  background: rgba(0,0,0,.8);
}
.announcement-content {
    width: 94%;
   /* height: 1540px;*/
    background: #25224A;
    border-radius:15px;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    .announcement-header {
        height: 148px;
        line-height: 148px;
        text-align: center;
        font-size: 54px;
        font-weight: bold;
        background: #2a215c;
        position: relative;
        .close {
            width: 55px;
            height: 55px;
            position: absolute;
            right: 40px;
            top: 40px;
            background: url('//txwl.internetbaidu.com/mobileTXW_3.0/index/close.png') no-repeat;
            background-size: cover;
        }
    }
    div.main {
        width: 86%;
        margin: 0 auto;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        padding-bottom: 40px;
        flex:1;
        overflow-y: scroll;
        .btns {
            height: 160px;
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            span {
                width: 230px;
                height: 80px;
                border: 1px solid #8d8c94;
                opacity: 0.4;
                font-size: 32px;
                text-align: center;
                line-height: 80px;
                border-radius: 40px;
                margin: 0 30px;
                &.active {
                    background:linear-gradient(110deg,#E6006A 0%,#F83725 100%);
                    opacity: 1;
                    border-color: transparent;
                }
            }
        }
        .content {
            width: 100%;
            font-size: 42px;
            text-align: justify;
            line-height: 60px;
            padding-top: 10px;
            overflow-y: scroll;
            flex:1;
            p {
                span {
                    font-weight: bold;
                }
            }
            h2 {
                color: #D2BD88;
                font-weight: bold;
                padding: 20px 0 30px;
            }
            img {
                display: block;
                width: 100%;
            }
          .imgBg{
            background:url('//txwl.internetbaidu.com/mobileTXW_3.0/index/jmzh.png') center center/ 100% no-repeat;
            height: calc(100vw * 0.85 * 0.94 * (727 / 853));
          }
        }
    }
}
</style>
