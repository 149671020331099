<template>
    <div class="index-contact">
        <div class="contact-warp" v-if="show">
            <div class="contact-content">
                <div class="close" @click="show = false;"></div>
                <h2>联系我们</h2>
                <ul>
                    <li>
                        <a href="line://">
                            <img src="//txwl.internetbaidu.com/mobileTXW_3.0/index/line.png" alt="">
                            <p>@TXKJ18899</p>
                        </a>
                    </li>
                  <li>
                    <a href="https://t.me/TX_GROUP998">
                      <img src="//txwl.internetbaidu.com/mobileTXW_3.0/index/telegram.png" alt="">
                      <p>@TX_GROUP998</p>
                    </a>
                  </li>
                     <!-- <li>
                        <a href="https://t.me/TX_GROUP">
                            <img src="//txwl.internetbaidu.com/mobileTXW_3.0/index/telegram.png" alt="">
                            <p>@TX_GROUP</p>
                        </a>
                    </li> -->

                   <!--  <li>
                        <a href="https://pt.im/txwlgfzh">
                            <img src="//txwl.internetbaidu.com/mobileTXW_3.0/index/photo.png" alt="">
                            <p>Potato</p>
                        </a>
                    </li>
                    <li>
                        <a href="https://wpa.qq.com/msgrd?v=3&uin=632299999&site=qq&menu=yes">
                            <img src="//txwl.internetbaidu.com/mobileTXW_3.0/index/qq.png" alt="">
                            <p>QQ</p>
                        </a>
                    </li>
                    <li>
                        <a href="mailto:txkj8899@gmail.com">
                            <img src="//txwl.internetbaidu.com/mobileTXW_3.0/index/email.png" alt="">
                            <p>E-mail</p>
                        </a>
                    </li> -->
                </ul>
                <div class="contact-phone">
                  <!--   <p>
                        <em>包网咨询:</em>
                        <a href="tel:+63-921-950-9999">+63 921 950 9999</a>
                    </p>
                    <p>
                        <em>API咨询:</em>
                        <a href="tel:+63-998-167-6789">+63 998 167 6789</a>
                    </p> -->
<!--                      <p>
                        <em>电话</em>
                        <a href="tel:+63-998-167-6789">+63 998 167 6789</a>
                    </p>-->
                    <p>
                        <em>邮箱</em>
                        <a href="email:txkj8899@gmail.com">txkj8899@gmail.com</a>
                    </p>
                </div>
            </div>
        </div>
        <div class="contact-btn" @click="show = !show;">
            <img src="//txwl.internetbaidu.com/mobileTXW_3.0/index/phone.png" alt="">
        </div>
    </div>
</template>
<script>
export default {
  name: "contact",
  data () {
    return {
        show: false
    };
  }
}
</script>
<style lang="less" scoped>
.contact-warp {
    position: fixed;
    z-index: 999;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, .7);
}
.contact-content {
    width: 990px;
    //height: 1040px;
    height:850px;
    background: #25224A;
    border-radius:15px;
    position: absolute;
    z-index: 999;
    top: 20%;
    left: 50%;
    margin-left: -495px;
    .close {
        width: 55px;
        height: 55px;
        position: absolute;
        right: 40px;
        top: 40px;
        background: url('//txwl.internetbaidu.com/mobileTXW_3.0/index/close.png') no-repeat;
        background-size: cover;
    }
    h2 {
        font-size: 66px;
        font-weight: bold;
        text-align: center;
        line-height: 80px;
        margin: 80px 0 50px;

    }
    ul {
       border-top: 1px solid #413d6d;
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-evenly;
        box-sizing: border-box;
        padding: 100px 0;
        li {
           /* width: 186px;*/
            height: 256px;
            a {
              display: flex;
              justify-content: center;
              width: 100%;
              height: 100%;
              flex-direction: column;
              align-items: center;
            }
            &:nth-child(3n) {
                margin-right: 0;
            }
            img {
                display: block;
                width: 186px;
                height: 186px;
            }
            p {
                font-size: 36px;
                color: #9CA5BB;
                text-align: center;
                height: 70px;
                line-height: 70px;
            }
        }
    }
    .contact-phone {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding:0 70px ;
        p {
            display: flex;
            align-items: center;
            font-weight: bold;
            height: 100px;
            em {
               font-size: 45px;
            //    width: 220px;
               text-align: left;
               margin-right: 30px;
               font-weight: 400;
            }
            a {
                color: #7e849a;
                font-size: 48px;
                padding: 12px 60px 12px 100px;
                border-radius: 50px;
                //background: #be2546 url('//txwl.internetbaidu.com/mobileTXW_3.0/index/contact.png') no-repeat;
                /* background:#1d193c url('//txwl.internetbaidu.com/mobileTXW_3.0/index/phoe.png') no-repeat;
                background-size: 110px 110px;
                background-position: 0 center*/
                background:#1d193c url('//txwl.internetbaidu.com/mobileTXW_3.0/index/email.png') no-repeat;
                background-size: 110px 110px;
                background-position: 0 center
            }
        }
      /*  p:nth-child(2) a {
            background:#1d193c url('//txwl.internetbaidu.com/mobileTXW_3.0/index/email.png') no-repeat;
            background-size: 110px 110px;
            background-position: 0 center
        }*/
    }
}
.contact-btn {
    width: 147px;
    height: 135px;
    background: url('//txwl.internetbaidu.com/mobileTXW_3.0/index/float.png') no-repeat;
    background-size: cover;
    position: fixed;
    z-index: 999;
    right: 0;
    top: 75%;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
        display: block;
        width: 49px;
        height: 59px;
    }
}
</style>
