<template>
    <div>
        <div class="tab-header">
            <img class="logo" src="//txwl.internetbaidu.com/mobileTXW_3.0/index/logo.png" alt="">
            <div :class="['menu', openMenu ? 'openMenu' : '' ]" @click="openMenu = !openMenu"></div>
        </div>
        <ul ref="munuList" :class="['tab-menu', 'animate__animated', openMenu ? 'animate__bounceInDown' : 'animate__bounceOutUp']">
            <li v-for="(item, index) in menuList" :key="index">
                <router-link :to="item.url" @click.native="openMenu = false;" v-if="!item.jump">{{item.title}}</router-link>
                <a :href="item.url" v-if="item.jump">{{item.title}}</a>
            </li>
        </ul>
        <!-- 公告 -->
      <announcement></announcement>
    </div>
</template>
<script>
export default {
  name: "headerNav",
  data () {
    return {
        openMenu: false,
        menuList: [
            { title: '首页', url: '/index'},
            { title: '天下包网', url: '/home'},
          /*   { title: 'WG视讯', url: 'http://m.txh-demo.com', jump: true}, */
            { title: '大唐棋牌', url: '/chess'},
            { title: '大唐捕鱼', url: '/fish'},
            { title: '展会资讯', url: '/exhibition'},
            { title: '成功案例', url: 'https://m.txwl.in', jump: true}, 
            // { title: '立即体验', url: '/experience'}
        ],
    };
  },
  mounted() {
      setTimeout(() => {
        this.$refs.munuList.style.visibility = 'visible';
      }, 1000)
  }
}
</script>
<style lang="less" scoped>
.tab-header{
    width: 100%;
    height: 135px;
    background: #1B1936;
    position: fixed;
    z-index: 9999;
    top: 0;
    left: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .logo {
        width: 420px;
        height: auto;
        margin-left: 45px;
    }
    .menu {
        width: 105px;
        height: 105px;
        margin-right: 45px;
        background-image: url('//txwl.internetbaidu.com/mobileTXW_3.0/index/menu.png');
        background-size: 100% 100%;
        &.openMenu {
            background-image: url('//txwl.internetbaidu.com/mobileTXW_3.0/index/menu-close.png');
        }
    }
}
.tab-menu {
    width: 100vw;
    height: calc(100vh - 135px);
    background: rgba(27, 25, 54, 0.9);
    position: fixed;
    z-index: 9999;
    top: 135px;
    left: 0;
    padding-top: 10%;
    visibility: hidden;
    overflow-x: hidden;
    overflow-y: auto;
    li, a {
        display: block;
        width: 100%;
        height: 178px;
        line-height: 178px;
        text-align: center;
        color: #C5CAD4;
        font-size: 54px;
    }
    a.router-link-active {
        background-color: #25224A;
        color: #F12042;
    }
}
</style>