<template>
    <div class="vedio-warp" :style="{backgroundImage: `url(${options.bg})`}">
        <div class="video-main">
            <video ref="video" controls="controls" :src="options.src" ></video>
            <img class="poster" :src="options.poster" alt="" v-if="!play" @click="videoPlay">
            <img class="close" src="//txwl.internetbaidu.com/mobileTXW_3.0/index/close.png" alt="" v-if="play" @click="close">
        </div>
    </div>
</template>
<script>
export default {
  name: "txVideo",
  props: {
      options: {
          type: Object,
          default() {
              return {}
          }
      }
  },
  data () {
    return {
        play: false
    };
  },
  methods: {
      videoPlay() {
          this.play = true
          this.$refs.video.play()
      },
      close() {
          this.play = false
          this.$refs.video.pause()
      }
  }
}
</script>
<style lang="less" scoped>
.vedio-warp {
    width: 100%;
    height: 728px;
    background-size: 100% 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    .video-main {
      width: 1036px;
      height: 582px;
      position: relative;
      video {
      width: 100%;
      height: 100%;
      }
      img.poster {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        z-index: 10;
      }
      img.close {
        position: absolute;
        width: 56px;
        height: 56px;
        top: 20px;
        right: 20px;
        z-index: 10;
      }
    }
  }
</style>