import Vue from 'vue'
import App from './App.vue'

import router from './router'

//全局组件
import components from '@/common/index.js'
Object.keys(components).forEach(key => {
  Vue.component(key, components[key])
})

// animate.css
import animated from'animate.css'
Vue.use(animated)

// swiper
import VueAwesomeSwiper from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'
Vue.use(VueAwesomeSwiper)

// reset.css
import '@/assets/reset.css'

Vue.prototype.events = new Vue() 

// animation 函数
import utils from './utils'
Vue.prototype.utils = utils

// axios
// import './utils/axios'

// vue-lazyload
import './utils/lazy'

Vue.config.productionTip = false

const vm = new Vue({
  render: h => h(App),
  router
}).$mount('#app')

export default vm
