import headerNav from './header-nav.vue'
import footerCopy from './footer-copy.vue'
import contact from './contact.vue'
import txVideo from './tx-video.vue'
import txImg from './tx-img.vue'
import announcement from './announcement.vue'

// Import component
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';

export default {
    headerNav,
    footerCopy,
    contact,
    txVideo,
    txImg,
    announcement,
    Loading
}