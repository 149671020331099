import Vue from 'vue'
import VueLazyload from 'vue-lazyload'
Vue.use(VueLazyload)

// or with options 也可以直接在这里设置参数
Vue.use(VueLazyload, {  
//   preLoad: 1.3,   //预加载的宽高比
//   error: 'dist/error.png',  // 加载失败图片
//   loading: 'dist/loading.gif',  // 加载中图片
  attempt: 1//尝试加载次数
})