<template>
    <div id="footer" :class="pos === 'normal' ? 'normal' : 'abs'">
        Copyright © 2016 - 2020 天下网络 All Right Reserved
    </div>
</template>
<script>
export default {
  name: "footerCopy",
  props: {
    pos: {
      type: String,
      default: 'normal'
    }
  },
  data () {
    return {
    };
  }
}
</script>
<style lang="less" scoped>
#footer {
  font-size: 30px;
  color: #535270;
  width: 100%;
  text-align: center;
  &.abs {
    position: absolute;
    z-index: 99;
    bottom: 60px;
    left: 0;
  }
  &.normal {
    line-height: 80px;
    padding-bottom: 40px;
  }
}
</style>