const animateCSS = (element, animationName, callback) => {
  const node = document.querySelector(element)
  if (!node) return

  node.classList.add('animate__animated', ...animationName)

  const handleAnimationEnd = () => {
    node.classList.remove('animate__animated', ...animationName)
    node.removeEventListener('animationend', handleAnimationEnd)

    if (typeof callback === 'function') callback(node)
  }

  node.addEventListener('animationend', handleAnimationEnd)
}

export default {
    animateCSS
}
  
