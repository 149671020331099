import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

const routers = [
    {
        path: '/',
        redirect: '/index'
    },
    {
        path: '/index',
        component: () => import('@/components/index/index.vue')
    },
    {
        path: '/home',
        component: () => import('@/components/home/index.vue')
    },
    {
        path: '/live-video',
        component: () => import('@/components/live-video/live-video.vue')
    },
    {
        path: '/chess',
        component: () => import('@/components/chess/index.vue')
    },
    {
        path: '/fish',
        component: () => import('@/components/fish/index.vue')
    },
    {
        path: '/exhibition',
        component: () => import('@/components/exhibition/index.vue')
    },

    {
        path: '/experience',
        component: () => import('@/components/experience/index.vue')
    }
]

export default new Router({
    mode: 'history',
    routes: [ ...routers ],
    scrollBehavior () {
        return { x: 0, y: 0 }
      }
})